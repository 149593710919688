import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { fallbackImages } from '../../config';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import trackAction from '../../Utilities/tracker';
import DialogWrapper from '../../Components/DialogWrapper';

import ChatProfile from '../../Components/ChatProfile';
import getTwilioUserIdentity from '../../Utilities/getTwilioUserIdentity';

// import PubNubReact from 'pubnub';

import { Auth } from 'aws-amplify';
import axios from 'axios';
import { eventId } from '../../config';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';
import twilioCreateJoinOneOnOneConversation from '../../Store/Actions/twilioCreateJoinOneOnOneConversation';

const useStyles = (theme) => ({
  root: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media: {
    height: 0,
    paddingTop: '40%', // 16:9
    margin: '0% 0%',
  },
  title: {
    // paddingBottom: theme.spacing(1),
    lineHeight: 1.5,
  },
  body: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  avatarPhoto: {
    width: '4rem',
    height: '4rem',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    borderRadius: '50%',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  lineSpeakerTitle: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerCompany: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    fontSize: '150%',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
});

class PosterCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      buttonHover: false,
      contactOpen: false,
      currentContact: {},
    };
  }

  // componentDidMount(){
  //   this.props.poster.contacts.sort((a, b) => {
  //     if (a.order === null){
  //       a.order = 1000
  //     }

  //     if (b.order === null){
  //       b.order = 1000
  //     }

  //     return a.order - b.order
  //   })
  // }

  handleExpandClick() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  handleContactOpen(presenter) {
    this.setState({ contactOpen: true, currentContact: presenter });
  }
  handleContactClose() {
    this.setState({ contactOpen: false, currentContact: {} });
    // this.setState({ buttonHover: true, contactOpen: false, currentContact: {}, });
  }

  async groupAdd() {
    try {
      const data = await Auth.currentSession();

      if (!this.props.poster.groupInterested) {
        await axios({
          method: 'POST',
          url: `/group/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
            resource_type: this.props.poster.accepted_type,
          },
        });
        const event = {
          action: 'Group Interest',
          resource_type: this.props.poster.accepted_type,
          resource_id: this.props.poster.abstract_id,
          url_visited: '/posterhall',
          resource_account_id: this.props.poster.account_id,
          resource_account_name: null,
          resource_name: this.props.poster.title,
        };

        trackAction(event);
        this.props.handleGroupAdd();
      } else {
        await axios({
          method: 'DELETE',
          url: `/group/${eventId}/${this.props.poster.abstract_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
          },
        });

        this.props.handleGroupAdd();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async upvotePoster() {
    try {
      const data = await Auth.currentSession();

      if (!this.props.poster.liked) {
        await axios({
          method: 'POST',
          url: `/likes/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
            resource_type: this.props.poster.accepted_type,
          },
        });

        const event = {
          action: 'Liked',
          resource_type: this.props.poster.accepted_type,
          resource_id: this.props.poster.abstract_id,
          url_visited: '/posterhall',
          resource_account_id: this.props.poster.account_id,
          resource_account_name: null,
          resource_name: this.props.poster.title,
        };

        trackAction(event);

        this.props.handleUpvote();
      } else {
        await axios({
          method: 'DELETE',
          url: `/likes/${eventId}/${this.props.poster.abstract_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
          },
        });
        this.props.handleUpvote();
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes } = this.props;

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.props.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    let characterCount = 0;
    let splitTitle = this.props.poster.title.split(' ');
    let modifiedTitle = '';
    let characterLimit = 120;

    splitTitle.map((string) => {
      characterCount = characterCount + string.length;

      if (characterCount <= characterLimit) {
        modifiedTitle = modifiedTitle + ' ' + string;
      }

      return string;
    });

    if (characterCount > characterLimit) {
      modifiedTitle = modifiedTitle + '...';
    }

    let cardImage;
    if (this.props.poster.supporting_file_url) {
      cardImage = this.props.poster.supporting_file_url;
    } else if (this.props.topicMap[this.props.poster.topic]) {
      cardImage = this.props.topicMap[this.props.poster.topic];
    } else if (this.props.event.event.topic_map[this.props.poster.topic]) {
      cardImage = this.props.event.event.topic_map[this.props.poster.topic];
    } else {
      cardImage = fallbackImages.posterCard;
    }

    let level;
    let color;
    let lightColor;
    switch (this.props.poster.sponsor_level) {
      case 'Signature Sponsor':
        color = '#0600b1';
        lightColor = '#554fff';
        level = 'Signature Sponsor';
        break;
      case 'Platinum Sponsor':
        color = '#996ae6';
        lightColor = '#c2a7f0';
        level = 'Platinum Sponsor';
        break;
      case 'Gold Sponsor':
        color = '#ff9a0d';
        lightColor = '#fcb044';
        level = 'Gold Sponsor';
        break;
      case 'Silver Sponsor':
        color = '#9e9e9e';
        lightColor = '#c0c0c0';
        level = 'Silver Sponsor';
        break;
      default:
        color = '#ffffff00';
        lightColor = '#ffffff00';
        level = '';
        break;
    }
    let firstPresenter = null;

    let contactsSection = null;

    if (this.props.poster.contacts) {
      this.props.poster.contacts.forEach((contact) => {
        // console.log(
        //   '🚀 ~ file: PosterCard.js ~ line 302 ~ PosterCard ~ this.props.poster.contacts.forEach ~ contact',
        //   contact
        // );
        if (
          contact.roles.includes('presenter') ||
          contact.roles.includes('Presenter')
        ) {
          if (!firstPresenter) {
            firstPresenter = {
              first_name: contact.first_name,
              last_name: contact.last_name,
              account_name: contact.account_name
                ? contact.account_name
                : contact.department,
            };
          }
        }
      });

      contactsSection = this.props.poster.contacts.map((contact, index) => {
        if (
          contact.roles.includes('presenter') ||
          contact.roles.includes('Presenter')
        ) {
          return (
            <Grid
              container
              key={index}
              style={{ alignItems: 'center' }}
              onClick={(e) => {
                e.stopPropagation();
                this.handleContactOpen(contact);

                const me = this.props.user.user;
                const isMe = getTwilioUserIdentity(me) === contact?.email;

                if (!isMe && contact?.email) {
                  this.props
                    .twilioCreateJoinOneOnOneConversation(contact?.email)
                    .catch((error) => {
                      console.log(
                        '🚀 ~ file: PosterCard.js ~ line 334 ~ PosterCard ~ contactsSection=this.props.poster.contacts.map ~ User not registered',
                        error.message
                      );
                    });
                }
              }}
            >
              <Grid item xs={3}>
                <ChatProfile
                  identity={contact?.email}
                  size={60}
                  photo_url={contact?.photo_url}
                  directory={undefined}
                  onClick={undefined}
                />
              </Grid>
              <Grid item xs={9} style={{ paddingLeft: '8px' }}>
                <Typography
                  className={classes.lineSpeakerName}
                  align="center"
                  // variant='h6'
                  color="textPrimary"
                >
                  {contact.first_name + ' ' + contact.last_name}
                </Typography>
                {contact.title ? (
                  <Typography
                    className={classes.lineSpeakerTitle}
                    align="center"
                  >
                    {contact.title}
                  </Typography>
                ) : (
                  <React.Fragment />
                )}
                {contact.department ? (
                  <Typography
                    className={classes.lineSpeakerTitle}
                    align="center"
                  >
                    {contact.department}
                  </Typography>
                ) : (
                  <React.Fragment />
                )}
                {contact.account_name ? (
                  <Typography
                    className={classes.lineSpeakerCompany}
                    align="center"
                  >
                    {contact.account_name}
                  </Typography>
                ) : (
                  <React.Fragment />
                )}
              </Grid>
              <Divider
                className={classes.divider}
                style={{ width: '90%' }}
                variant="middle"
              />
            </Grid>
          );
        }
        return null;
      });
    }

    // used in contact modal
    const keyValue = (key, value) => {
      if (value) {
        return (
          <>
            <b>{key}:</b> {value}
          </>
        );
      }
    };

    return (
      <Card
        className={classes.root}
        raised={this.state.isHovering}
        onMouseEnter={() => this.handleChange(true, 'isHovering')}
        onMouseLeave={() => this.handleChange(false, 'isHovering')}
        onClick={
          this.state.buttonHover || this.state.contactOpen
            ? null
            : (event) =>
                this.props.toggleFullscreenHandlerOn(
                  event,
                  this.props.poster.abstract_id
                )
        }
      >
        <div>
          <Hidden xsDown>
            <CardMedia
              className={classes.media}
              image={cardImage}
              title="Poster Presentation"
              src="image"
            />
          </Hidden>
          {this.props.poster.sponsor_level ? (
            <Typography
              variant="h5"
              className={classes.sponsorLevel}
              align="center"
              color="textPrimary"
              style={{
                backgroundImage: `linear-gradient(${color}, ${lightColor})`,
              }}
            >
              {level}
            </Typography>
          ) : (
            <React.Fragment />
          )}
          <CardContent style={{ paddingBottom: '0px' }}>
            <Hidden xsDown>
              <Typography
                className={classes.title}
                variant="h6"
                color="textPrimary"
                align="center"
              >
                {modifiedTitle}
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Typography
                className={classes.title}
                variant="body1"
                color="textPrimary"
                align="center"
              >
                <b>{modifiedTitle}</b>
              </Typography>
            </Hidden>
            <Divider className={classes.divider} variant="middle" />
            {contactsSection}
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              align="left"
            >
              <b>Topic: </b>
              {this.props.poster.topic.split(';').join(', ')}
            </Typography>
            <Hidden xsDown>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                align="left"
              >
                {this.props.poster.cleared_for_public_view ===
                'Yes - Approved' ? (
                  <React.Fragment>
                    <b>Access: </b>Public
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <b>Access: </b>Private
                  </React.Fragment>
                )}
              </Typography>
            </Hidden>
          </CardContent>
        </div>
        <CardActions disableSpacing>
          <Tooltip
            title={
              <Typography variant="subtitle2">
                I want to save this poster to my briefcase to access later
              </Typography>
            }
            aria-label="add"
          >
            {favorited ? (
              <IconButton
                aria-label="remove from briefcase"
                onClick={() =>
                  this.props.deleteBriefcaseElement(
                    this.props.poster.abstract_id
                  )
                }
                onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                onMouseLeave={() => this.handleChange(false, 'buttonHover')}
              >
                <WorkIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="add to briefcase"
                onClick={() =>
                  this.props.addBriefcaseElement(
                    'Poster',
                    this.props.poster.abstract_id,
                    this.props.poster.title,
                    this.props.poster.account_id,
                    this.props.user.user.account_id,
                    this.props.poster.account_name,
                    firstPresenter
                  )
                }
                onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                onMouseLeave={() => this.handleChange(false, 'buttonHover')}
              >
                <WorkOutlineIcon />
              </IconButton>
            )}
          </Tooltip>
          <Tooltip
            title={
              <Typography variant="subtitle2">I like this poster</Typography>
            }
            aria-label="add"
          >
            <IconButton
              aria-label="upvote"
              onClick={() => {
                this.upvotePoster();
              }}
              onMouseEnter={() => this.handleChange(true, 'buttonHover')}
              onMouseLeave={() => this.handleChange(false, 'buttonHover')}
            >
              {this.props.poster.liked ? (
                <ThumbUpIcon />
              ) : (
                <ThumbUpIconOutlined />
              )}
              <Typography variant="subtitle1" style={{ marginLeft: '4px' }}>
                {this.props.poster.count}
              </Typography>
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <Typography variant="subtitle2">
                I am interested in potential uses of this technology/process in
                my group
              </Typography>
            }
            aria-label="add"
          >
            <IconButton
              aria-label="groupadd"
              onClick={() => {
                this.groupAdd();
              }}
              onMouseEnter={() => this.handleChange(true, 'buttonHover')}
              onMouseLeave={() => this.handleChange(false, 'buttonHover')}
            >
              {this.props.poster.groupInterested ? (
                <GroupIcon />
              ) : (
                <GroupAddOutlinedIcon />
              )}
              <Typography variant="subtitle1" style={{ marginLeft: '4px' }}>
                {this.props.poster.groupCount}
              </Typography>
            </IconButton>
          </Tooltip>
        </CardActions>
        {this.props.poster.live_now ? (
          <Typography
            variant="h5"
            className={classes.sponsorLevel}
            align="center"
            color="textPrimary"
            style={{
              backgroundImage: `linear-gradient(${this.props.event.event.main_color}, ${this.props.event.event.light_color})`,
            }}
          >
            Live Now
          </Typography>
        ) : null}
        <DialogWrapper
          open={this.state.contactOpen}
          handleClose={() => this.handleContactClose()}
          header={undefined}
          children={undefined}
          footer={undefined}
        >
          <Grid container justify="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {/* {this.state.currentContact.photo_url && ( */}
              <div
                alt={'Presenter'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <ChatProfile
                  identity={this.state?.currentContact?.email}
                  size={'4rem'}
                  photo_url={this.state?.currentContact?.photo_url}
                  directory={undefined}
                  onClick={undefined}
                />
                {/* // </div> */}
                {/* <img
                      className={classes.linePhoto}
                      src={this.state.currentContact.photo_url}
                      alt={'Presenter'}
                    /> */}
              </div>
              {/* // )} */}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.lineSpeakerName} variant="h6">
                {this.state.currentContact.first_name
                  ? `${this.state.currentContact.first_name} ${this.state.currentContact.last_name}`
                  : null}
              </Typography>
              <Typography className={classes.lineSpeakerInfo}>
                {keyValue('Title', this.state.currentContact.title)}
              </Typography>
              <Typography className={classes.lineSpeakerInfo}>
                {keyValue('Account', this.state.currentContact.account_name)}
              </Typography>
              <Typography variant="body1" className={classes.lineSpeakerInfo}>
                {keyValue('Department', this.state.currentContact.department)}
              </Typography>
              <Typography variant="body1" className={classes.lineSpeakerInfo}>
                {keyValue(
                  'Expertise',
                  this.state.currentContact.expertise_area
                )}
              </Typography>
              <Typography className={classes.lineSpeakerInfo}>
                {keyValue('Email', this.state.currentContact.email)}
              </Typography>
              <Typography variant="body1" className={classes.lineSpeakerInfo}>
                {keyValue('Biography', this.state.currentContact.bio)}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              <Button
                size="large"
                style={{ width: '40%', marginLeft: '30%', marginTop: '8px' }}
                onClick={() => this.handleContactClose()}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogWrapper>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
      twilioCreateJoinOneOnOneConversation:
        twilioCreateJoinOneOnOneConversation,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(PosterCard));
