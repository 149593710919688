import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Grid, Paper } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import PosterCard from './PosterCard';
import PosterListItem from './PosterListItem';
import PosterFull from './PosterFull';
import Dialog from '../../Components/DialogWrapper';
import { withRouter } from 'react-router';
import { eventId } from '../../config';
import trackAction from '../../Utilities/tracker';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  filter: {
    // margin: '0% 0% 1% 0%',
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  paginationGrid: {
    alignSelf: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  // random: {
  //   width: '80%',
  // },
  topicFilter: {
    fontWeight: '500',
    color: '#000000',
    fontSize: '1.2rem',
  },
  filterGrid: {
    alignItems: 'center',
    // paddingLeft: theme.spacing(0.5),
  },
  tabs: {
    display: 'inline-flex',
  },
  tabSingle: {
    minWidth: 'auto',
  },
});

class PosterSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posters: [],
      posterSelected: false,
      posterIndex: null,
      posterId: null,
      postersFiltered: [],
      listFormat: false,
      filterString: '',
      filterCategory: 'topic',
      filtering: false,
      pageCountStart: 1,
      perPage: 4, //36
      totalPages: 1,
      page: 1,
      topicMap: null,
      isRandom: false,
      randomPageArray: null,
      tabValue: 0,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'poster_id') {
          this.setState({
            posterSelected: true,
            posterId: param[1],
          });
        }
      }
    }

    // this.startLiveNowCheck()
    // this.getTopics();
    this.getPosters();
    this.props.setBriefcase();

    const event = {
      action: 'Viewed',
      resource_type: 'PosterHall',
      resource_id: null,
      url_visited: '/posterhall',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props?.location?.search !== nextProps?.location?.search) {
      const query = new URLSearchParams(nextProps.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'poster_id') {
          this.setState({
            posterSelected: true,
            posterId: param[1],
          });
        }
      }

      return true;
    }
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    }
    return false; // added to handle thrown warning
    // else {
    //   console.log('scu', 3);
    //   this.setState({
    //     posterSelected: false,
    //     posterIndex: null,
    //     posterId: null,
    //   });
    //   return false;
    // }
  }

  // startLiveNowCheck = () => {
  //   this.intervalID = setInterval(
  //     this.changeLiveNow,
  //     250000 + Math.random() * 100000
  //   ); //check once every 5 or so minutes
  // };

  // changeLiveNow = () => {
  //   this.getPosters();
  // };

  // stopLiveNowCheck = () => {
  //   clearInterval(this.intervalID);
  // };

  // componentWillUnmount() {
  //   this.stopLiveNowCheck();
  // }

  // getTopics() {
  //   Auth.currentSession().then((data) => {
  //     axios({
  //       method: 'get',
  //       url: `/group/topics/${eventId}`,
  //       headers: {
  //         idtoken: data.idToken.jwtToken,
  //       },
  //       params: {
  //         first_row: this.state.pageCountStart,
  //         rows_per_page: this.state.perPage,
  //         search_columns: this.state.filterCategory,
  //         search_value: this.state.filterString,
  //       },
  //     })
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   });
  // }

  getPosters() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/group/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            first_row: this.state.pageCountStart,
            rows_per_page: this.state.perPage,
            filter_column: this.state.filterCategory,
            filter_value: this.state.filterString,
          },
        })
          .then((response) => {
            let totalPages = Math.ceil(
              response.data.recordsTotal / this.state.perPage
            );
            let randomPageArray = [];
            for (let i = 0; i < totalPages; i++) {
              randomPageArray.push(i + 1);
            }
            randomPageArray = randomPageArray.sort(() => Math.random() - 0.5);
            if (this.state.randomPageArray) {
              if (
                this.state.randomPageArray?.length &&
                totalPages === this.state.totalPages
              ) {
                randomPageArray = this.state.randomPageArray;
              }
            }
            let posters = response.data.data;
            // console.log(posters);
            if (this.state.isRandom) {
              posters = posters.sort(() => Math.random() - 0.5);
            }

            // set perPage way high, finds unique topics from posters.
            // let uniqueTopics = posters.map((poster) => poster.topic);
            // uniqueTopics = [...new Set(uniqueTopics)].sort();
            // console.log(uniqueTopics);

            // console.log(randomPageArray);
            this.setState({
              posters: posters,
              totalPages: totalPages,
              topicMap: JSON.parse(this.props.event.event.topic_map),
              randomPageArray,
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Poster Hall. ${error}`);
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Poster Hall. ${error}`);
        }
      });
  }

  togglePosterFullscreenOn = (event, id) => {
    this.setState({
      posterSelected: true,
      posterId: id,
    });
  };

  togglePosterFullscreenOff = () => {
    this.setState({
      posterSelected: false,
      posterId: null,
    });
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (e) => {
    this.setState({
      filterString: e.target.value,
      // filterCategory: category,
      filtering: true,
      pageCountStart: 1,
      page: 1,
    });
    this.getPosters();
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filtering: false,
      pageCountStart: 1,
      page: 1,
    });
    this.getPosters();
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - (this.state.perPage - 1),
      // isRandom: false,
      // tabValue: 1,
    });

    this.getPosters();
  };

  handleNextRandomSet = () => {
    let updatedRandomPageArray = this.state.randomPageArray;
    let currentPage = updatedRandomPageArray.shift();
    this.setState({
      page: currentPage,
      randomPageArray: updatedRandomPageArray,
      pageCountStart:
        currentPage * this.state.perPage - (this.state.perPage - 1),
      isRandom: true,
      tabValue: 1,
    });

    this.getPosters();
  };

  handleClose() {
    this.setState({
      posterSelected: false,
      posterIndex: null,
      posterId: null,
    });
  }

  handleUpvote = (index) => {
    let items = [...this.state.posters];
    let item = { ...items[index] };
    if (item.liked === true) {
      item.count = item.count - 1;
      item.liked = false;
    } else {
      item.count = item.count + 1;
      item.liked = true;
    }
    items[index] = item;
    this.setState({ posters: items });
  };

  handleUpvotePosterFull = (posterId) => {
    let items = [...this.state.posters];
    items.forEach((item) => {
      if (item.abstract_id === posterId) {
        if (item.liked === true) {
          item.count = item.count - 1;
          item.liked = false;
        } else {
          item.count = item.count + 1;
          item.liked = true;
        }
      }
    });
    this.setState({ posters: items });
  };

  handleGroupAdd = (index) => {
    let items = [...this.state.posters];
    let item = { ...items[index] };
    if (item.groupInterested === true) {
      item.groupInterested = false;
      item.groupCount = item.groupCount - 1;
    } else {
      item.groupInterested = true;
      item.groupCount = item.groupCount + 1;
    }
    items[index] = item;
    this.setState({ posters: items });
  };

  handleGroupAddPosterFull = (posterId) => {
    let items = [...this.state.posters];
    items.forEach((item) => {
      if (item.abstract_id === posterId) {
        if (item.groupInterested === true) {
          item.groupCount = item.groupCount - 1;
          item.groupInterested = false;
        } else {
          item.groupCount = item.groupCount + 1;
          item.groupInterested = true;
        }
      }
    });
    this.setState({ posters: items });
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
      isRandom: newValue === 1 ? true : false,
    });
    if (newValue === 1) {
      this.handleNextRandomSet();
      const event = {
        action: 'Clicked',
        resource_type: 'PosterHall / Randomize',
        resource_id: null,
        url_visited: '/posterhall',
        resource_account_id: null,
        resource_account_name: null,
        resource_name: null,
      };

      trackAction(event);
    }
  };

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/posterhall') {
        pageTitle = route.title;
      }
    });

    let sidebarOpen;
    if (this.props.sidebar.chatOpen || this.props.sidebar.open) {
      sidebarOpen = true;
    } else {
      sidebarOpen = false;
    }

    let pageBody;
    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Results Found
        </Typography>
      </Grid>
    );
    let contentLoading = (
      <Grid
        container
        className={classes.gridContainer}
        layout={'row'}
        spacing={2}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        {this.state.filtering && this.state.posters.length === 0
          ? noResultsFound
          : loadingGraphic}
      </Grid>
    );

    let allTopics = Object.keys(JSON.parse(this.props.event.event.topic_map));
    allTopics.shift(); //remove Default from the array
    let topicsMenuItems;
    topicsMenuItems = allTopics.map((topic) => {
      return (
        <MenuItem value={topic} key={topic}>
          <b>{topic}</b>
        </MenuItem>
      );
    });

    let sortModes = (
      <Tabs
        className={classes.tabs}
        value={this.state.tabValue}
        onChange={this.handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="scrollable"
        style={{ marginRight: '24px' }}
      >
        <Tab className={classes.tabSingle} label={'Ordered'} to="/event" />
        <Tab className={classes.tabSingle} label={'Random'} to="/event" />
      </Tabs>
    );

    let randomize = (
      <Button
        variant="contained"
        className={classes.button}
        onClick={this.handleNextRandomSet}
        style={{ margin: '4px 0px 0px 8px', width: '11rem', padding: '4px' }}
      >
        {/* <Switch
      checked={this.state.isRandom}
      onChange={this.handleRandomToggle}
      color="primary"
      name="isRandom"
      inputProps={{ 'aria-label': 'isRandom' }}
    /> */}
        Next Random Page
      </Button>
    );

    let paginationRandomize = (
      <>
        {!this.state.isRandom ? (
          <Grid
            align="left"
            key="pagination"
            item
            // xs={12}
            // sm={9}
            className={classes.paginationGrid}
          >
            <Pagination
              count={this.state.totalPages}
              page={this.state.page}
              onChange={this.handlePageChange}
              boundaryCount={2}
              siblingCount={1}
            />
          </Grid>
        ) : (
          <Grid
            item
            align="left"
            // xs={12} sm={3}
          >
            {randomize}
          </Grid>
        )}
      </>
    );

    let paginationBelow = (
      <Grid
        item
        container
        xs={12}
        style={{ padding: '4px 0px', marginBottom: '40px' }}
      >
        {!this.state.isRandom ? (
          <Grid
            align="left"
            key="pagination"
            item
            // xs={12}
            // sm={9}
            className={classes.paginationGrid}
          >
            <Pagination
              count={this.state.totalPages}
              page={this.state.page}
              onChange={this.handlePageChange}
              boundaryCount={2}
              siblingCount={1}
            />
          </Grid>
        ) : (
          <Grid
            item
            align="left"
            // xs={12} sm={3}
          >
            {randomize}
          </Grid>
        )}
      </Grid>
    );

    let topicFilter = (
      <>
        <Grid item align="right" xs={12} sm={6} style={{ marginRight: '8px' }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              <Typography variant="body1" className={classes.topicFilter}>
                Topic Filter
              </Typography>
            </InputLabel>
            <Select
              value={this.state.filterString}
              label="Topic"
              onChange={(e) => this.handleFilterClick(e)}
            >
              {topicsMenuItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          align="left"
          // xs={12} sm={2}
          style={{ alignSelf: 'end' }}
        >
          {this.state.filtering ? (
            <Button
              variant="contained"
              onClick={this.handleRemoveFilter}
              className={classes.button}
              style={{
                marginTop: '4px',
                width: '11rem',
                padding: '4px 16px',
              }}
            >
              Remove Filter
            </Button>
          ) : null}
        </Grid>
      </>
    );

    let gridListToggle = (
      // <Grid item align="right" xs={3}>
      <Hidden xsDown>
        <Button onClick={this.handleGridListToggle}>
          Grid
          <Switch
            checked={this.state.listFormat}
            onChange={this.handleGridListToggle}
            color="primary"
            name="listFormatSwitch"
            inputProps={{ 'aria-label': 'listFormatSwitch' }}
          />
          List
        </Button>
      </Hidden>
      // </Grid>
    );

    if (this.state.listFormat) {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            container
            className={classes.filterGrid}
            layout={'row'}
            spacing={2}
          >
            <Grid item container xs={6}>
              {paginationRandomize}
            </Grid>
            <Grid item xs={6} align="right">
              {sortModes}
              {gridListToggle}
            </Grid>
            <Grid item container xs={12} style={{ marginLeft: '8px' }}>
              {topicFilter}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {this.state.posters.map((poster, index) => {
              return (
                <PosterListItem
                  key={index}
                  index={index}
                  poster={poster}
                  toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                    this
                  )}
                  handleUpvote={() => this.handleUpvote(index)}
                  handleGroupAdd={() => this.handleGroupAdd(index)}
                />
              );
            })}
          </Grid>
          {paginationBelow}

          <Dialog
            open={this.state.posterSelected}
            handleClose={this.handleClose.bind(this)}
            scroll="body"
          >
            {this.state.posters.length ? (
              <PosterFull
                posterId={this.state.posterId}
                allPosters={this.state.posters}
                closeBooth={this.togglePosterFullscreenOff.bind(this)}
                handleUpvote={() =>
                  this.handleUpvotePosterFull(this.state.posterId)
                }
                handleGroupAdd={() =>
                  this.handleGroupAddPosterFull(this.state.posterId)
                }
              />
            ) : null}
          </Dialog>
        </Grid>
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            container
            className={classes.filterGrid}
            layout={'row'}
            spacing={0}
          >
            <Grid item container xs={6}>
              {paginationRandomize}
            </Grid>
            <Grid item xs={6} align="right">
              {sortModes}
              {gridListToggle}
            </Grid>
            <Grid item container xs={12} style={{ marginLeft: '8px' }}>
              {topicFilter}
            </Grid>
          </Grid>
          {this.state.posters.map((poster, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={sidebarOpen ? 6 : 4}
                lg={sidebarOpen ? 4 : 3}
                xl={sidebarOpen ? 3 : 2}
              >
                <PosterCard
                  topicMap={this.state.topicMap}
                  poster={poster}
                  index={index}
                  toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(
                    this
                  )}
                  handleUpvote={() => this.handleUpvote(index)}
                  handleGroupAdd={() => this.handleGroupAdd(index)}
                />
              </Grid>
            );
          })}
          {paginationBelow}
          <Dialog
            open={this.state.posterSelected}
            handleClose={this.handleClose.bind(this)}
            scroll="body"
          >
            {this.state.posters.length ? (
              <PosterFull
                posterId={this.state.posterId}
                allPosters={this.state.posters}
                closeBooth={this.togglePosterFullscreenOff.bind(this)}
                handleUpvote={(posterId) =>
                  this.handleUpvotePosterFull(posterId)
                }
                handleGroupAdd={() =>
                  this.handleGroupAddPosterFull(this.state.posterId)
                }
              />
            ) : null}
          </Dialog>
        </Grid>
      );
    }

    let page = this.state.posters.length > 0 ? pageBody : contentLoading;

    return page;
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(PosterSessions)));
