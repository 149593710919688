import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DataTable } from '../../Utilities/DataTable';
import { exhibitorGridOptions } from './ExhibitorGridOptions';
import clsx from 'clsx';
import axios from 'axios';
import { demoEnabled, eventId, syncServiceSid } from '../../config';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  centerButtons: {
    textAlign: 'center',
    lineHeight: 1.25,
  },
  toolbar: {
    minHeight: '64px', //required to make mobile behave properly
  },
}));

export default function ExhibitorList(props) {
  const classes = useStyles();
  const [exhibitorList, setExhibitorList] = useState([]);
  // access redux to get strapi
  const strapiPagesRedux = useSelector((state) => state.strapi);
  const eventInformation = useSelector((state) => state.event);
  // configure menu item

  useEffect(() => {
    const initialize = async () => {
      let data = await Auth.currentSession();
      // this query isn't returning enough. use exhibit hall query instead.
      await axios({
        method: 'get',
        url: `/exhibitions/${eventId}`,
        // url: `/shows/sponsorships/${eventId}/accepted`, // some exhibitors missing.
        headers: { idtoken: data.idToken.jwtToken },
        // params: { showId: eventId, order_by: 'last_name', order_dir: 'asc' },
      })
        .then((response) => {
          console.log(response.data.data);
          let exhibitors = response.data.data
            .map((exhibitor) => {
              switch (exhibitor.booth_type) {
                case '1':
                  exhibitor.sponsorship_level = 'Signature';
                  break;
                case '2':
                  exhibitor.sponsorship_level = 'Platinum';
                  break;
                case '3':
                  exhibitor.sponsorship_level = 'Gold';
                  break;
                case '4':
                  exhibitor.sponsorship_level = 'Silver';
                  break;
                default:
                  exhibitor.sponsorship_level = '';
              }
              return exhibitor;
            })
            .filter((exhibitor) => exhibitor.sponsorship_level !== '');
          console.log(exhibitors);
          setExhibitorList(exhibitors);
        })
        .catch((error) => console.log(error));
    };
    initialize().catch(console.error);
  }, []);

  //   return <div>Hello Exhibitor List</div>;
  return (
    <DataTable
      initialGridOptions={exhibitorGridOptions}
      initialRowData={exhibitorList}
      //   actionButtons={StaffGridActionButtons}
      type="exhibitor"
      setDeleting={(e) => this.handleDeletingChange(e, 'physical')}
      setEditing={(e) => this.handleEditingChange(e)}
      setCurrentPage={(e) => this.setcurrentStaff(e)}
    />
  );
}
