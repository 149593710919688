import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import trackAction from '../../Utilities/tracker';
import PosterChat from './SciArtChat';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
// import WorkIcon from '@material-ui/icons/Work';
// import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { eventId } from '../../config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  underTabsContainer: {
    // padding: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    textDecoration: 'none',
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linePhoto: {
    borderRadius: '50%',
    width: '5rem',
    height: '5rem',
    [theme.breakpoints.down('xs')]: {
      width: '3rem',
      height: '3rem',
    },
  },
  heroPhoto: {
    maxWidth: '100%',
    maxHeight: '66vh',
  },
  lineTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  lineSpeakerName: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  lineSpeakerInfo: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  tabSingle: {
    minWidth: 'auto',
  },
});

class PosterFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      poster: null,
      currentPosterTopicList: null,
      relatedPosters: null,
      upvote: false,
    };
  }

  topOfModal = React.createRef();

  scrollToTop = () => {
    if (this.topOfModal.current) {
      this.topOfModal.current.scrollIntoView();
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  navigate(poster) {
    this.getPoster(poster.abstract_id);
  }

  recordEvent = (data) => {
    const event = {
      action: 'Clicked',
      resource_type: 'Science As Art',
      resource_id: data.abstract_id,
      url_visited: '/scienceasart',
      resource_account_id: data.account_id,
      resource_account_name: data.account_name,
      resource_name: data.title,
    };

    trackAction(event);
  };

  topicStringToArray = (poster) => {
    const topics = [];
    let topicsSplit = poster.topic.split(';');
    topicsSplit.forEach((topic) => {
      topic = topic.trim();
      topics.push(topic);
    });
    return topics;
  };

  comparePosters = (
    posters,
    currentPosterId,
    currentTopicList,
    masterTopicListLength
  ) => {
    const relatedPosters = [];
    let pointSystem = {
      match: masterTopicListLength,
      miss: 2,
      extra: 3,
    };
    posters.forEach((poster) => {
      if (poster.abstract_id === currentPosterId) {
        // do nothing
      } else {
        let potentialTopicList = this.topicStringToArray(poster);

        let overlap = currentTopicList.filter((topic) =>
          potentialTopicList.includes(topic)
        );
        if (overlap.length > 0) {
          let masterLookup = {};

          for (let j in potentialTopicList) {
            masterLookup[potentialTopicList[j]] = potentialTopicList[j];
          }
          let matches = 0;
          let matchPoints = 0;
          let missPoints = 0;
          let extraPoints = 0;

          for (let i in currentTopicList) {
            if (typeof masterLookup[currentTopicList[i]] != 'undefined') {
              matchPoints =
                matchPoints + currentTopicList.length * pointSystem.match - i;
              matches = matches + 1;
            }
          }
          extraPoints = potentialTopicList.length - matches;
          missPoints = masterTopicListLength - extraPoints - matches;

          let relatedValue =
            matchPoints -
            missPoints * pointSystem.miss -
            extraPoints * pointSystem.extra;
          poster.relatedValue = relatedValue;

          relatedPosters.push(poster);
        }
      }
      relatedPosters.sort(function (a, b) {
        return b.relatedValue - a.relatedValue;
      });
    });
    return relatedPosters;
  };

  async componentDidMount() {
    try {
      this.getPoster(this.props.posterId);
    } catch (error) {
      console.log(error);
    }
  }

  async getPoster(potentialPosterId) {
    await this.setState({
      poster: null,
    });

    try {
      let posters = this.props.allPosters.filter(
        (a) => a.abstract_id === potentialPosterId
      );

      const currentTopicList = this.topicStringToArray(posters[0]);

      let masterTopicListDirty = [];

      this.props.allPosters.forEach((poster) => {
        let posterTopics = this.topicStringToArray(poster);
        masterTopicListDirty = [...masterTopicListDirty, ...posterTopics];
      });

      let masterTopicList = [...new Set(masterTopicListDirty)];

      const relatedPosters = await this.comparePosters(
        this.props.allPosters,
        posters[0].abstract_id,
        currentTopicList,
        masterTopicList.length
      );
      if (relatedPosters.length > 5) {
        relatedPosters.length = 5;
      }
      if (posters[0].contacts) {
        posters[0].presenters = posters[0].contacts
          .filter((contact) => contact.role.toLowerCase().includes('presenter'))
          // .filter((contact) => contact.role.toLowerCase() === 'presenter')
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });
        posters[0].authors = posters[0].contacts
          .filter((contact) => contact.role.toLowerCase().includes('author'))
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });
        posters[0].submitters = posters[0].contacts
          .filter((contact) => contact.role.toLowerCase().includes('submitter'))
          .sort((a, b) => {
            if (a.order === null) {
              a.order = 1000;
            }

            if (b.order === null) {
              b.order = 1000;
            }

            return a.order - b.order;
          });
      } else {
        posters[0].presenters = [];
        posters[0].authors = [];
        posters[0].submitters = [];
      }

      this.setState({
        poster: posters[0],
        currentPosterTopicList: currentTopicList,
        relatedPosters: relatedPosters,
      });

      this.recordEvent(posters[0]);

      if (posters[0].liked) {
        this.setState({ upvote: true });
      } else {
        this.setState({ upvote: false });
      }
    } catch (error) {
      alert(error);
    }
    this.scrollToTop();
  }

  async upvotePoster() {
    this.setState({ tabValue: this.state.tabValue });

    try {
      const data = await Auth.currentSession();

      if (!this.state.upvote) {
        await axios({
          method: 'POST',
          url: '/likes/' + eventId,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
            resource_type: this.state.poster.accepted_type,
          },
        });
        const event = {
          action: 'Liked',
          resource_type: this.state.poster.accepted_type,
          resource_id: this.state.poster.abstract_id,
          url_visited: '/scienceasart',
          resource_account_id: this.state.poster.account_id,
          resource_account_name: null,
          resource_name: this.state.poster.title,
        };

        trackAction(event);
        this.setState({ upvote: true });
        this.props.handleUpvote(this.state.poster.abstract_id);
      } else {
        await axios({
          method: 'DELETE',
          url: '/likes/' + eventId + '/' + this.state.poster.abstract_id,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.state.poster.abstract_id,
          },
        });
        this.setState({ upvote: false });
        this.props.handleUpvote(this.state.poster.abstract_id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  briefcaseSelect() {
    this.setState({ tabValue: this.state.tabValue });

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.state.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    if (favorited) {
      this.props.deleteBriefcaseElement(this.state.poster.abstract_id);
    } else {
      this.props.addBriefcaseElement(
        'Poster',
        this.state.poster.abstract_id,
        this.state.poster.title,
        this.state.poster.account_id,
        this.props.user.user.account_id
      );
    }
  }

  render() {
    const { classes } = this.props;
    let posterContent;

    if (!this.state.poster) {
      return <CircularProgress color="inherit" />;
    }

    if (this.state.poster) {
      switch (this.state.tabValue) {
        case 1:
          // let meetMeButton = null

          // if(this.state.poster.presenters.find((e) => this.props.user.user.email === e.email)){
          //   if(this.state.poster.presentation_host_link){
          //     meetMeButton = (
          //       <a target="_blank" rel="noopener noreferrer" href={this.state.poster.presentation_host_link} style={{textDecoration: 'none'}}>
          //         <Button color="inherit" variant="contained" className={classes.button}>
          //           Meet Me
          //         </Button>
          //       </a>
          //     )
          //   } else if(this.state.poster.presentation_link){
          //     meetMeButton = (
          //       <a target="_blank" rel="noopener noreferrer" href={this.state.poster.presentation_link} style={{textDecoration: 'none'}}>
          //         <Button color="inherit" variant="contained" className={classes.button}>
          //           Meet Me
          //         </Button>
          //       </a>
          //     )
          //   } else {
          //     meetMeButton = (
          //       <Button disabled color="inherit" variant="contained" className={classes.button}>
          //         Meet Me
          //       </Button>
          //     )
          //   }
          // } else {
          //   if(this.state.poster.presentation_link){
          //     meetMeButton = (
          //       <a target="_blank" rel="noopener noreferrer" href={this.state.poster.presentation_link} style={{textDecoration: 'none'}}>
          //         <Button color="inherit" variant="contained" className={classes.button}>
          //           Meet Me
          //         </Button>
          //       </a>
          //     )
          //   } else {
          //     meetMeButton = (
          //       <Button disabled color="inherit" variant="contained" className={classes.button}>
          //         Meet Me
          //       </Button>
          //     )
          //   }
          // }

          posterContent = (
            <React.Fragment>
              <Grid item xs={12}>
                <Hidden xsDown>
                  <Typography variant="h4" color="textPrimary">
                    {this.state.poster.title}
                  </Typography>
                  {/* <br/>
                <Typography variant='h6' color='textPrimary'>
                  <b>Topic: </b>{this.state.poster.topic.split(';').join(', ')}
                </Typography> */}
                </Hidden>
                <Hidden smUp>
                  <Typography variant="body1" color="textPrimary">
                    <b>{this.state.poster.title}</b>
                  </Typography>
                  {/* <br/>
                <Typography variant='body2' color='textPrimary'>
                  <b>Topic: </b>{this.state.poster.topic.split(';').join(', ')}
                </Typography> */}
                </Hidden>
                {/* <br/>
              <Divider className={classes.divider} variant="fullWidth" /> */}
              </Grid>
              {/* <Grid item container xs={12} style={{height: '100%',}}>
              <Grid item xs={4}>
                {meetMeButton}
              </Grid>
              <Grid item xs={4}>
                {this.state.poster.presentation_pdf ?
                <React.Fragment>
                  <a target="_blank" rel="noopener noreferrer" href={this.state.poster.presentation_pdf} style={{textDecoration: 'none'}}>
                    <Button color="inherit" variant="contained" className={classes.button}>
                      Poster PDF
                    </Button>
                  </a>
                </React.Fragment>
                : 
                <Button disabled color="inherit" variant="contained" className={classes.button}>
                  Poster PDF
                </Button>
                }
              </Grid>
              <Grid item xs={4}>
                {this.state.poster.presentation_video ? 
                <a target="_blank" rel="noopener noreferrer" href={this.state.poster.presentation_video} style={{textDecoration: 'none'}}>
                  <Button color="inherit" variant="contained" className={classes.button}>
                    Intro Video
                  </Button>
                </a>
                : 
                <Button disabled color="inherit" variant="contained" className={classes.button}>
                  Intro Video
                </Button>
                }
              </Grid>
            </Grid> */}
              <Grid item xs={3} />
              <Grid item xs={6}>
                {this.state.poster.presentation_video ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.poster.presentation_video}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      color="inherit"
                      variant="contained"
                      className={classes.button}
                    >
                      Supporting Video
                    </Button>
                  </a>
                ) : (
                  <Button
                    disabled
                    color="inherit"
                    variant="contained"
                    className={classes.button}
                  >
                    Supporting Video
                  </Button>
                )}
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={12}>
                <Divider className={classes.divider} variant="fullWidth" />
                <br />
                <Typography variant="h6" color="textPrimary" align="center">
                  <b>Description</b>
                </Typography>
                <pre style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    align="justify"
                  >
                    {this.state.poster.content}
                  </Typography>
                </pre>
                {/* <Divider className={classes.divider} variant="fullWidth" />
              <Typography variant='h6' color='textPrimary' align='center'>
                <b>Related Posters</b>
              </Typography>
              {this.state.relatedPosters.map((poster, index) => {
                return (
                <div key={index}>
                  <Button 
                    color='primary' 
                    variant='outlined' 
                    size='large'
                    style={{margin: '4px 0px',}}
                    onClick={()=> this.navigate(poster)}
                  >
                    <Typography variant='body1' color='textPrimary' align='center'>
                      {poster.title}
                    </Typography>
                  </Button>
                </div>
                )
              })} */}
              </Grid>
            </React.Fragment>
          );
          break;
        case 2:
          const keyValue = (key, value) => {
            if (value) {
              return (
                <>
                  <b>{key}:</b> {value}
                </>
              );
            }
          };
          posterContent = (
            <React.Fragment>
              <Grid item xs={12}>
                {this.state.poster.presenters.length ? (
                  <React.Fragment>
                    <Typography variant="h5" color="textPrimary">
                      <b>Artists</b>
                    </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.state.poster.presenters.map((presenter, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.lineSpeakerName}
                          variant="h6"
                        >
                          {`${presenter.first_name} ${presenter.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {presenter.photo_url ? (
                          <img
                            className={classes.linePhoto}
                            src={presenter.photo_url}
                            alt={'Presenter'}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={10} style={{ paddingLeft: '8px' }}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', presenter.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Account', presenter.account_name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Department', presenter.department)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Expertise', presenter.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', presenter.email)}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.lineSpeakerInfo}
                        >
                          {keyValue('Biography', presenter.bio)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          className={classes.divider}
                          variant="fullWidth"
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              {/* <Grid item xs={12} style={{paddingTop: '3vw'}}>
                {this.state.poster.authors.length ?
                  <React.Fragment>
                    <Typography variant='h5' color='textPrimary'>
                      <b>Authors</b>
                      </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                    </React.Fragment>
                    : <React.Fragment/>}
                  {this.state.poster.authors.map((author, index)=>{
                    return (
                      <Grid container key={index}>
                        <Grid item xs={12}>
                          <Typography className={classes.lineSpeakerName} variant='h6'>
                            {`${author.first_name} ${author.last_name}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} >
                          {author.photo_url ? (
                          <img
                          className={classes.linePhoto}
                          src={author.photo_url}
                          alt={"Presenter"}
                          />
                          ) : (
                              null
                          )}
                        </Grid>
                        <Grid item xs={10} style={{paddingLeft: '8px',}}>
                          <Typography className={classes.lineSpeakerInfo}>
                            {keyValue('Title', author.title)}
                          </Typography>
                          <Typography className={classes.lineSpeakerInfo}>
                            {keyValue('Account', author.account_name)}
                          </Typography>
                          <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Department', author.department)}
                          </Typography>
                          <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Expertise', author.expertise_area)}
                          </Typography>
                          <Typography className={classes.lineSpeakerInfo}>
                            {keyValue('Email', author.email)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.divider} variant="fullWidth" />
                        </Grid>
                      </Grid>
                      )
                    })}
                </Grid> */}
            </React.Fragment>
          );
          break;
        default:
          posterContent = <div>No Content</div>;
          break;
      }
    } else {
      posterContent = <div></div>;
      return <div></div>;
    }

    // let favorited = false;
    // if (this.props.briefcase.briefcase) {
    //   for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
    //     if (this.props.briefcase.briefcase[n].resource_id === this.state.poster.abstract_id) {
    //       favorited = true;
    //       break;
    //     }
    //   }
    // }

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
        id="posterModalFull"
      >
        {<span ref={this.topOfModal} />}
        <Grid item xs={12} sm={2} align="left">
          <Button onClick={() => this.props.closeBooth()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {/* {favorited ?
                <Tab 
                  className={classes.tabSingle} 
                  onClick={() => {
                    this.briefcaseSelect()
                  }} 
                  icon = {<WorkIcon/>}
                />
                :
                <Tab 
                  className={classes.tabSingle} 
                  onClick={() => {
                    this.briefcaseSelect()
                  }}
                  icon = {<WorkOutlineIcon />}
              /> } */}
            {this.state.upvote ? (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.upvotePoster();
                }}
                icon={<ThumbUpIcon />}
              />
            ) : (
              <Tab
                className={classes.tabSingle}
                onClick={() => {
                  this.upvotePoster();
                }}
                icon={<ThumbUpIconOutlined />}
              />
            )}
            <Tab className={classes.tabSingle} label={'Content'} />
            <Tab className={classes.tabSingle} label={'Artists'} />
          </Tabs>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={false} sm={2} align="right" />
        </Hidden>
        {this.state.tabValue === 1 ? (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              className={classes.heroPhoto}
              src={this.state.poster.supporting_file_url}
              alt={'Content'}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={6}>
          <div className={classes.paper}>
            <Grid
              className={classes.underTabsContainer}
              container
              spacing={2}
              justify="center"
              alignItems="center"
            >
              {posterContent}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.paper}>
            <PosterChat
              poster={this.state.poster}
              channel={this.state.poster.abstract_id}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(PosterFull));
