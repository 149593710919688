import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import axios from 'axios';

// Configure Redux
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './Store/Reducers/index';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import 'react-dropzone-uploader/dist/styles.css';

import RootScreen from './Containers/RootScreen';

// Configure Amplify
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

// Main configuration
import { endpoint } from './config';

const persistConfig = {
  key: 'user',
  storage: storage,
  whitelist: ['user', 'sidebar', 'debug', 'event', 'strapi'], // which reducer want to store
};

const pReducer = persistReducer(persistConfig, reducers);
const middleware = applyMiddleware(thunk);
const composedMiddleware = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : middleware;
const store = createStore(pReducer, composedMiddleware);
const persistor = persistStore(store);

aws_exports.oauth.redirectSignIn =
  typeof window !== 'undefined'
    ? window.location.origin + '/registration'
    : 'http://localhost:3000/';
aws_exports.oauth.redirectSignOut =
  typeof window !== 'undefined'
    ? window.location.origin + '/registration'
    : 'http://localhost:3000/';

Amplify.configure(aws_exports);

// Configure Axios
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:5000';
} else if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
  axios.defaults.baseURL = 'http://dev.planetconnect.com';
} else {
  axios.defaults.baseURL = endpoint;
}

axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// GLobal Catch for All Outgoing Requests
axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log('interceptor error: OUTGOING', error);
    return Promise.reject(error);
  }
);

// Global Catch for All Incoming Responses
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('interceptor error: INCOMING', error);
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RootScreen />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
// serviceWorker.unregister();
