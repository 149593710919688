import { SET_STRAPI_ELEMENTS } from './types';
import axios from 'axios';
import { eventId } from '../../config';

export default function setStrapiElements() {
  return (dispatch, getState) => {
    axios({
      method: 'get',
      url: `https://cms.planetconnect.com/shows?show_id=${eventId}`,
      headers: { Authorization: '' },
    }).then((response) => {
      dispatch(setStrapiElementsAsync(response.data[0].menu));
    });
  };
}

function setStrapiElementsAsync(elements) {
  return {
    type: SET_STRAPI_ELEMENTS,
    payload: elements,
  };
}
