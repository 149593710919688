import { combineReducers } from 'redux';
import SidebarReducer from './sidebar';
import UserReducer from './user';
import BriefcaseReducer from './briefcase';
import ChatReducer from './chat';
import SnackbarReducer from './snackbar';
import DebugReducer from './debug';
import EventReducer from './event';
import TwilioReducer from './twilio';
import StrapiReducer from './strapi';

const rootReducer = combineReducers({
  sidebar: SidebarReducer,
  user: UserReducer,
  briefcase: BriefcaseReducer,
  chat: ChatReducer,
  snackbar: SnackbarReducer,
  debug: DebugReducer,
  event: EventReducer,
  twilio: TwilioReducer,
  strapi: StrapiReducer,
});

export default rootReducer;
