import { SET_STRAPI_ELEMENTS } from '../Actions/types';

const initialState = {
  strapi: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STRAPI_ELEMENTS:
      return {
        // ...state, // this line is not present because strapi is being set with latest data every time
        strapi: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
