import MeetingRoomOne from './Assets/Rooms/MeetingRoom1.jpg';
import AttendeeGuide from './Assets/Assets/VirtualEnvironment-AttendeeUserGuide.pdf';

// Unprotected Data

const colors = {
  // set default colors for proper render before initial event is set in redux
  main: '#122857',
  light: '#3b60ad',
  chatAccent: '#122857',
  iconGray: '#757575',
  disabledGray: '#e0e0e0',
  disbledText: '#a6a6a6',
  backgroundText: '#5A5A5A',
  backgroundGray: '#F2F2F2',
};

// Configure Default Fallbacks
const faviconLocation =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/pci-icon.png'; // set fallback favicon

const landingPage = {
  // set fallback landing page content
  banner: 'https://pci-admin-kfc-portal.s3.amazonaws.com/Demo_Banner_v1a.png',
  content:
    'This is the Virtual Environment for the PlanetConnect Demo Environment!',
};

const title = 'Demo'; // fallback webpage title

const posterSessionTopicImages = {
  Default: 'https://pci-site-assets.s3.amazonaws.com/DemoEvent/Default.jpg', // set fallback poster card image
};

// Configure Lobby Image

const lobbyImageMap = {
  name: 'my-map',
  areas: [
    {
      name: 'welcomeVideo',
      formalName: 'Welcome Video',
      shape: 'poly',
      coords: [
        23,
        665, //bottom left
        42,
        498, //top left
        355,
        515, //top right
        345,
        675, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
    },
    {
      name: 'agenda',
      formalName: 'Agenda',
      shape: 'poly',
      coords: [
        253,
        893, //bottom left
        275,
        735, //top left
        523,
        736, //top right
        515,
        890, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/agenda',
    },
    {
      name: 'discussionforum',
      formalName: 'Networking',
      shape: 'poly',
      coords: [
        540,
        890, //bottom left
        550,
        740, //top left
        792,
        740, //top right
        787,
        882, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/discussionforum',
    },
    {
      name: 'posterhall',
      formalName: 'Poster Hall',
      shape: 'poly',
      coords: [
        810,
        883, //bottom left
        818,
        740, //top left
        1065,
        713, //top right
        1070,
        867, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/posterhall',
    },
    {
      name: 'exhibithall',
      formalName: 'Exhibit Hall',
      shape: 'poly',
      coords: [
        1095,
        862, //bottom left
        1090,
        708, //top left
        1160,
        685, // middle top
        1370,
        575, // top right
        1385,
        822, //bottom right
        // 1280, 800, // middle bottom
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/exhibithall',
    },
    {
      name: 'auditorium',
      formalName: 'Auditorium',
      shape: 'poly',
      coords: [
        1410,
        822, //bottom left
        1395,
        557, //top left
        1707,
        375, //top right
        1755,
        755, //bottom right
      ],
      strokeColor: '',
      lineWidth: '4',
      href: '/auditorium',
    },
    {
      name: 'signature',
      formalName: 'Signature',
      shape: 'poly',
      coords: [
        674,
        430, //top left
        1050,
        470, //top right
        1060,
        635, //bottom right
        670,
        605, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
    },
    {
      name: 'planetconnect',
      formalName: 'PlanetConnect',
      shape: 'poly',
      coords: [
        14,
        1192, //top left
        424,
        1192, //top right
        424,
        1322, //bottom right
        14,
        1322, //bottom left
      ],
      strokeColor: '',
      lineWidth: '4',
    },
  ],
};

const lobbyImage =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/Lobby-CDX_v2.jpg'; // location source of lobby image

// Configure Custom Signature Sponsor Exhibitor

const signatureSponsorId = '1691992000076472059'; //id used when user navigates when clicking on signature sponsor banner lobby image
const signatureSponsorName = 'Sysmex Inostics'; //name used when user navigates when clicking on signature sponsor banner lobby image

const signatureSponsorLogo =
  'https://pci-site-assets.s3.amazonaws.com/ExhibitorLogos/Sysmex.png'; // location source of signature logo
const signatureSponsorUrl = 'exhibithall/?exhibition_id=1691992000076472059'; // Used to set where user navigates when clicking on signature sponsor banner lobby image

const perkinBoothMTS2021 =
  'https://pci-admin-kfc-portal.s3.amazonaws.com/PerkinBooth-MTS2021.png'; // location source of signature sponsor booth background

// True Configuration

const eventId = '1691992000048841633'; // Sets the event id of the event
const accountId = '1691992000015906109'; // Sets the account id of a user registering for the event

// Twilio Config

// Default Conversations Service
// const conversationsServiceSid=IS63492420ac934168a8e313a8cc1e61a8

const landingpageOnly = true;

const MerckSSOEnabled = false;
const OktaSSOEnabled = false;

const demoEnabled = false;
const conversationsServiceSid = 'IS59094fc663ba4d538408b85483c42186';
// Default Sync Service
const syncServiceSid = 'IS23879de72c268949e4755e575b522431';

const allowedRegistrationDomains = []; // comma separated array of substrings of emails allowed to register for the event as the accountId account
const allowedSigninDomains = ['@']; // comma separated array of substrings of emails allowed to enter the event

const endpoint = 'https://core-api.planetconnect.com/'; // the main endpoint the front end points to - used to configure axios
const registrationPage = 'https://planetconnect.com/'; // depricated -  used as the place where users could go to register when authorized but not registered

const drawerWidth = 240; // left side open navigation bar width
const rightDrawerWidth = 340; // right side chat width

const fallbackImages = {
  // fallback images for exhibitor and agenda cards
  exhibitCard: MeetingRoomOne,
  agendaCard: MeetingRoomOne,
};

const attendeeGuide = AttendeeGuide;
// const sponsorBanner = SponsorBanner

console.log('Deployed: 5/6/22 at 15:00 EDT');

export {
  // fallback protection
  posterSessionTopicImages, //used as fallback
  fallbackImages, // used as fallback
  landingPage, //used as fallback
  title, //used as fallback
  colors, //used as fallback
  faviconLocation, //used as fallback
  // true configuration
  endpoint,
  drawerWidth,
  rightDrawerWidth,
  lobbyImageMap,
  lobbyImage,
  eventId,
  accountId,
  conversationsServiceSid,
  syncServiceSid,
  signatureSponsorLogo,
  signatureSponsorUrl,
  signatureSponsorId,
  signatureSponsorName,
  // staged for remote configuration
  registrationPage,
  attendeeGuide,
  allowedRegistrationDomains,
  allowedSigninDomains,
  // perkinSpecific
  perkinBoothMTS2021,
  // SSO Attributes
  MerckSSOEnabled,
  OktaSSOEnabled,
  demoEnabled,
  // landing page specific
  landingpageOnly,
};
