import React, { Component } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import ExhibitCard from './ExhibitCard';
import ExhibitBooth from './ExhibitBooth';
import { withRouter } from 'react-router';
import ExhibitListItem from './ExhibitListItem';
import Filter from './ExhibitFilter';
import Dialog from '../../Components/DialogWrapper';
import { eventId } from '../../config';
import trackAction from '../../Utilities/tracker';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  gridContainer: {},
  gridCard: {
    maxWidth: '50%',
    width: '100%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class ExhibitionHall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitors: [],
      boothSelected: false,
      boothId: null,
      listFormat: false,
      filterString: '',
      filterCategory: 'account_name',
      filtering: false,
      pageCountStart: 1,
      perPage: 10,
      totalPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    this.locationCheck();

    this.getExhibits();
    this.props.setBriefcase();

    const event = {
      action: 'Viewed',
      resource_type: 'ExhibitHall',
      resource_id: null,
      url_visited: '/exhibithall',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    trackAction(event);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props?.location?.search !== nextProps?.location?.search) {
      this.locationCheck(nextProps);

      return true;
    }
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    }
    // else {
    //   this.setState({
    //     boothSelected: false,
    //     boothId: null,
    //   });
    //   return false;
    // }
  }

  locationCheck(optionalProps) {
    let myProps = optionalProps ? optionalProps : this.props;
    if (myProps.location.search) {
      const query = new URLSearchParams(myProps.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'exhibition_id') {
          this.setState({
            boothSelected: true,
            boothId: param[1],
          });
        }
        if (param[0] === 'product_id') {
          Auth.currentSession()
            .then((data) => {
              axios({
                method: 'get',
                url: '/products/' + param[1],
                headers: {
                  idtoken: data.idToken.jwtToken,
                },
              })
                .then((response) => {
                  this.setState({
                    boothSelected: true,
                    boothId: response.data[0].exhibition_id,
                  });
                })
                .catch((error) => {
                  if (myProps.debug.debug) {
                    alert(`Error: Could not display Exhibit Hall. ${error}`);
                  }
                });
            })
            .catch((error) => {
              if (myProps.debug.debug) {
                alert(`Error: Could not display Exhibit Hall. ${error}`);
              }
            });
        }
      }
    }
  }
  getExhibits() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/exhibitions/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            order_by: 'booth_type',
            order_dir: 'asc',
            search_columns: this.state.filterCategory,
            search_value: this.state.filterString,
          },
        }).then(
          (response) => {
            let exhibitors = response.data.data.filter(
              (exhibit) => exhibit.type !== 'Digital Lab'
            );

            let authorizedExhibitors = [];

            for (let i = 0; i < exhibitors.length; i++) {
              if (exhibitors[i].is_public) {
                authorizedExhibitors.push(exhibitors[i]);
              } else {
                if (
                  this.props.user.user.roles.includes('Attendee') ||
                  this.props.user.user.roles.includes('Pharma Attendee')
                ) {
                  authorizedExhibitors.push(exhibitors[i]);
                }
              }
            }

            authorizedExhibitors.sort(function (a, b) {
              let levelA = a.level_requested;
              let levelB = b.level_requested;
              if (levelA === 'Event Host' && levelB !== 'Event Host') {
                return 1;
              }
              if (levelA !== 'Event Host' && levelB === 'Event Host') {
                return -1;
              }
              return 0;
            });

            authorizedExhibitors.forEach((exhibit) => {
              if (exhibit.type === 'Internal') {
                exhibit.sortByNumber = 2.5;
              } else {
                exhibit.sortByNumber = parseInt(exhibit.booth_type);
              }
            });

            authorizedExhibitors.sort(function (a, b) {
              return a.sortByNumber - b.sortByNumber;
            });

            this.setState({
              exhibitors: authorizedExhibitors,
              totalPages: Math.ceil(
                response.data.recordsFiltered / this.state.perPage
              ),
            });
          },
          (error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Exhibit Hall. ${error}`);
            }
          }
        );
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Exhibit Hall. ${error}`);
        }
      });
  }

  toggleBoothFullscreenOn = (event, id, company) => {
    this.setState({
      boothSelected: true,
      boothId: id,
    });
  };

  toggleBoothFullscreenOff = () => {
    this.setState({
      boothSelected: false,
      boothId: null,
    });

    if (!this.state.exhibitors.length) {
      this.getExhibits();
    }
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - 9,
    });

    this.getExhibits();
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (string, category) => {
    this.setState({
      filterString: string,
      filterCategory: category,
      filtering: true,
    });
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filterCategory: '',
      filtering: false,
    });
  };

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/exhibithall') {
        pageTitle = route.title;
      }
    });

    let pageBody = null;
    let exhibitors;
    if (this.state.filterString && this.state.filterCategory) {
      exhibitors = this.state.exhibitors.filter((exhibit) => {
        if (exhibit[this.state.filterCategory]) {
          return exhibit[this.state.filterCategory]
            .toLowerCase()
            .includes(this.state.filterString.toLowerCase());
        } else {
          return false;
        }
      });
    } else {
      exhibitors = this.state.exhibitors;
    }
    let sidebarOpen;
    if (this.props.sidebar.chatOpen || this.props.sidebar.open) {
      sidebarOpen = true;
    } else {
      sidebarOpen = false;
    }

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Results Found
        </Typography>
      </Grid>
    );
    let contentLoading = (
      <Grid
        container
        className={classes.gridContainer}
        layout={'row'}
        spacing={2}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        <Filter
          handleGridListToggle={this.handleGridListToggle.bind(this)}
          handleRemoveFilter={this.handleRemoveFilter.bind(this)}
          handleFilterClick={this.handleFilterClick.bind(this)}
          listFormat={this.state.listFormat}
          filterString={this.state.filterString}
          filterCategory={this.state.filterCategory}
          filtering={this.state.filtering}
        />
        {this.state.filtering && this.state.exhibitors.length === 0
          ? noResultsFound
          : loadingGraphic}
      </Grid>
    );

    if (this.state.listFormat) {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          spacing={2}
          style={{ marginBottom: '40px' }}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Filter
            handleGridListToggle={this.handleGridListToggle.bind(this)}
            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
            handleFilterClick={this.handleFilterClick.bind(this)}
            listFormat={this.state.listFormat}
            filterString={this.state.filterString}
            filterCategory={this.state.filterCategory}
            filtering={this.state.filtering}
          />
          <Grid item xs={12}>
            {exhibitors.map((exhibit, index) => {
              return (
                <ExhibitListItem
                  key={index}
                  index={index}
                  exhibit={exhibit}
                  toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                    this
                  )}
                />
              );
            })}
          </Grid>
          <Dialog
            open={this.state.boothSelected}
            handleClose={this.toggleBoothFullscreenOff.bind(this)}
            scroll="body"
          >
            <ExhibitBooth
              boothId={this.state.boothId}
              closeBooth={this.toggleBoothFullscreenOff.bind(this)}
            />
          </Dialog>
        </Grid>
      );
    } else {
      pageBody = (
        <Grid
          container
          className={classes.gridContainer}
          layout={'row'}
          alignItems="stretch"
          spacing={2}
          style={{ marginBottom: '40px' }}
        >
          <Grid item xs={12}>
            <Paper>
              <Typography
                style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
                variant="h4"
                color="textPrimary"
              >
                {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Filter
            handleGridListToggle={this.handleGridListToggle.bind(this)}
            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
            handleFilterClick={this.handleFilterClick.bind(this)}
            listFormat={this.state.listFormat}
            filterString={this.state.filterString}
            filterCategory={this.state.filterCategory}
            filtering={this.state.filtering}
          />
          {exhibitors.map((exhibit, index) => {
            switch (exhibit.booth_type) {
              case '1':
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
              case '2':
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
              case '3':
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid
                    key={index}
                    item
                    sm={6}
                    md={sidebarOpen ? 4 : 3}
                    lg={sidebarOpen ? 3 : 2}
                    className={classes.gridCard}
                  >
                    <ExhibitCard
                      exhibit={exhibit}
                      index={index}
                      toggleFullscreenHandlerOn={this.toggleBoothFullscreenOn.bind(
                        this
                      )}
                    />
                  </Grid>
                );
            }
          })}
          <Dialog
            open={this.state.boothSelected}
            handleClose={this.toggleBoothFullscreenOff.bind(this)}
            scroll="body"
          >
            <ExhibitBooth
              boothId={this.state.boothId}
              closeBooth={this.toggleBoothFullscreenOff.bind(this)}
            />
          </Dialog>
        </Grid>
      );
    }

    let page = this.state.exhibitors.length > 0 ? pageBody : contentLoading;

    return page;
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(ExhibitionHall)));
