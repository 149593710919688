import { SET_EVENT_ELEMENTS } from './types';
import axios from 'axios';
import { eventId } from '../../config';

export default function setEventElements() {
  return (dispatch, getState) => {
    const items = getState().event;

    axios({
      method: 'get',
      url: '/events/' + eventId,
    }).then((response) => {
      let changeState = false;

      if (
        response.data.main_color !== items.event.main_color ||
        response.data.light_color !== items.event.light_color ||
        response.data.favicon_location !== items.event.favicon_location ||
        response.data.banner_location !== items.event.banner_location ||
        response.data.landing_page_content !==
          items.event.landing_page_content ||
        response.data.title !== items.event.title ||
        response.data.under_construction !== items.event.under_construction ||
        response.data.allowed_registration_domains !==
          items.event.allowed_registration_domains ||
        response.data.allowed_signin_domains !==
          items.event.allowed_signin_domains
      ) {
        changeState = true;
      }

      if (changeState && !items.event.available_routes) {
        dispatch(setEventElementsAsync(response.data));
      }
    });
  };
}

function setEventElementsAsync(elements) {
  return {
    type: SET_EVENT_ELEMENTS,
    payload: elements,
  };
}
