import React, { useEffect, useState, useRef } from 'react';
import assert from 'assert';
import Typography from '@material-ui/core/Typography';
import twilioLeaveIfNotFollowing from '../Store/Actions/twilioLeaveIfNotFollowing';
import { useDispatch, useSelector } from 'react-redux';
import twilioJoin from '../Store/Actions/twilioJoin';
import ChatConversation from './ChatConversation';
import ActiveUsers from './ActiveUsers';
import isAdmin from '../Utilities/isAdmin';
import subscribe from '../Assets/Icons/subscribe.png';
import getTwilioUserIdentity from '../Utilities/getTwilioUserIdentity';

export default function GroupConversation({
  eventId,
  type, // poster or exhibit
  uniqueName,
  friendlyName,
  exhibitionId,
  abstractId,
  logo,
}) {
  const page = 'poster';
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event.event);
  const user = useSelector((state) => state.user.user);
  const userTwilio = useSelector((state) => state.twilio.user?.client);
  const allTwilioUsers = useSelector((state) => state.twilio?.users);
  // const conversationsClient = useSelector(getConversationsClient);
  // const syncClient = useSelector(getSyncClient);

  // const [list, setList] = useState(null);
  // const [connectedUsers, setConnectedUsers] = useState({});
  const [conversationSid, setConversationSid] = useState();
  // console.log(
  //   '🚀 ~ file: GroupConversation.js ~ line 43 ~ conversationSid',
  //   conversationSid
  // );

  const chatRef = useRef();

  const conversation = useSelector(
    (state) => state.twilio.conversations?.[conversationSid]
  );
  const openConversation = useSelector(
    (state) => state.twilio.openConversations?.[page]
  );
  const admins = conversation?.attributes?.admins || [];
  const adminIdentities = [];
  let someoneOnline = false;
  const findOnline = () => {
    admins.forEach((admin) => {
      const identity = admin.identity || admin.email;
      adminIdentities.push(identity);
      if (allTwilioUsers?.[identity]?.isOnline) {
        someoneOnline = allTwilioUsers?.[identity]?.isOnline;
      }
    });
  };
  findOnline();
  const isEmbedded = abstractId || exhibitionId;

  useEffect(() => {
    let loadedConversation;
    (async () => {
      try {
        // Join if needed.
        // console.log('joining group conversation');
        loadedConversation = await dispatch(
          twilioJoin({
            eventId,
            type,
            friendlyName,
            uniqueName,
            abstractId,
            exhibitionId,
            logo,
          })
        );
        assert(loadedConversation?.sid, 'could not join conversation');
        // console.log(
        //   '🚀 ~ file: GroupConversation.js ~ line 72 ~ loadedConversation',
        //   loadedConversation
        // );

        setConversationSid(loadedConversation.sid);
        // // kick off loading open conversation
        // dispatch(
        //   setOpenConversation({
        //     page,
        //     conversation: { sid: loadedConversation.sid },
        //   })
        // );
      } catch (err) {
        console.log(
          '🚀 ~ file: GroupConversation.js ~ line 42 ~ err',
          err.message
        );
      }
    })();
    return async () => {
      if (loadedConversation !== null && loadedConversation?.sid) {
        dispatch(twilioLeaveIfNotFollowing(loadedConversation?.sid));
      }
    };
  }, [friendlyName, uniqueName, type]);

  if (!conversationSid) return null;
  // console.log(
  //   '🚀 ~ file: GroupConversation.js ~ line 106 ~ openConversation',
  //   openConversation
  // );

  const isUserAdmin = isAdmin(conversation?.client, userTwilio);
  // const conversationLoaded = conversation?.client;
  const meFollowing = conversation?.myAttributes?.following;
  // console.log(
  //   '🚀 ~ file: GroupConversation.js ~ line 96 ~ conversation',
  //   conversation
  // );
  // let meParticipant;
  // if (conversation.client?.participants) {
  //   for (let [key, value] of conversation.client.participants) {
  //     if (value.identity === userTwilio.identity) {
  //       meParticipant = value;
  //     }
  //   }
  // }
  // console.log(
  //   '🚀 ~ file: GroupConversation.js ~ line 94 ~ meParticipant',
  //   meParticipant
  // );

  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        // backgroundColor: '#F2F2F2',
        backgroundColor: 'white',
      }}
    >
      {someoneOnline && (
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          style={{
            backgroundImage: `linear-gradient(${event.main_color}, ${event.light_color})`,
            width: '100%',
            color: 'white',
            position: 'relative',
            textShadow: '0px 5px 20px #000000CC',
            fontSize: '150%',
            paddingTop: '4px',
            paddingBottom: '4px',
          }}
        >
          Live Now
        </Typography>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 15,
          boxShadow: '0 4px 7px -7px #000',
          backgroundColor: 'white',
          marginBottom: 2,
        }}
      >
        <div style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          {/* <div style={{ fontSize: 24 }}>{conversation.friendlyName}</div> */}
          {/* <div
            style={{
              fontSize: 14,
              color: '#757575',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 15,
            }}
          >
            <img src={users} style={{ width: 15, height: 9, margin: 5 }} />
            {anonymous} anonymous, {active} active
            
          </div> */}
          <ActiveUsers
            uniqueName={uniqueName}
            friendlyName={friendlyName}
            embedded={isEmbedded}
            onClick={
              isUserAdmin
                ? () => {
                    // console.log('click', chatRef?.current);
                    if (chatRef?.current?.toggleShowAllParticipants) {
                      chatRef.current.toggleShowAllParticipants();
                    }
                  }
                : null
            }
          />
        </div>
        {
          // Admins cannot unfollow their rooms
          conversation && !isUserAdmin && (
            <div
              style={{
                flexDirection: 'row',
                fontSize: 16,
                color: 'white',
                backgroundColor: '#122857',
                borderRadius: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px 15px',
                cursor: 'pointer',
              }}
              onClick={async () => {
                console.log(
                  '🚀 ~ file: GroupConversation.js ~ line 188 ~ onClick={ ~ conversation',
                  conversation
                );
                console.log(
                  '🚀 ~ file: GroupConversation.js ~ line 190 ~ onClick={ ~ getTwilioUserIdentity(user)',
                  getTwilioUserIdentity(user)
                );
                console.log(
                  '🚀 ~ file: GroupConversation.js ~ line 188 ~ onClick={ ~ conversation',
                  conversation.participants?.[getTwilioUserIdentity(user)]
                );
                const myIdentity = getTwilioUserIdentity(user);
                const participant =
                  await conversation.client.getParticipantByIdentity(
                    myIdentity
                  );
                console.log(
                  '🚀 ~ file: GroupConversation.js ~ line 201 ~ onClick={ ~ participant getParticipantByIdentity()',
                  participant
                );
                if (participant) {
                  participant.updateAttributes({
                    following: !meFollowing,
                  });
                }
                // if (conversation.participants?.[getTwilioUserIdentity(user)]) {
                //   conversation.participants[
                //     getTwilioUserIdentity(user)
                //   ].updateAttributes({
                //     following: !meFollowing,
                //   });
                // } else {
                //   console.log('fallback');
                //   if (conversation.client?.participants) {
                //     for (let participant of conversation.client.participants) {
                //       // conversation.client?.participants?.forEach((participant) => {
                //       if (participant.identity === myIdentity) {
                //         console.log(
                //           '🚀 ~ file: GroupConversation.js ~ line 204 ~ //conversation.client?.participants?.forEach ~ participant',
                //           participant,
                //           !meFollowing
                //         );
                //         await participant.updateAttributes({
                //           following: !meFollowing,
                //         });
                //       }
                //       // });
                //     }
                //   }
                // }
              }}
            >
              <img
                src={subscribe}
                style={{ width: 14, height: 14, margin: 5 }}
              />
              {meFollowing ? 'Unfollow' : 'Follow'}
            </div>
          )
        }
      </div>
      <div style={{ backgroundColor: '#F2F2F2' }}>
        <ChatConversation
          ref={chatRef}
          conversationSid={conversationSid}
          page={page}
        />
      </div>
    </div>
  );
}
