import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import axios from 'axios';
import { demoEnabled, eventId, landingpageOnly } from '../config.js';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  centerButtons: {
    textAlign: 'center',
    lineHeight: 1.25,
  },
  toolbar: {
    minHeight: '64px', //required to make mobile behave properly
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const [abstractAvailable, setAbstractAvailable] = useState();
  const [strapiPages, setStrapiPages] = useState();
  const [pageButtons, setPageButtons] = useState();
  // access redux to get strapi
  const strapiPagesRedux = useSelector((state) => state.strapi);
  const userInformation = useSelector((state) => state.user);
  const eventInformation = useSelector((state) => state.event);
  // configure menu item
  const [tabIndex, setTabIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const initialize = async () => {
      await axios({
        method: 'get',
        url: 'forms/' + eventId,
        // headers: { idtoken: data.idToken.jwtToken },
        params: { showId: eventId },
      })
        .then((response) => {
          let formObject = response.data.data;
          let abstractSubmission = formObject.filter(
            (item) => item.type === 'abstract_submission'
          )[0];
          setAbstractAvailable(abstractSubmission.is_enabled);
        })
        .catch((error) => console.log(error));
      setStrapiPages(strapiPagesRedux.strapi);
    };
    initialize().catch(console.error);
  }, [strapiPagesRedux.strapi]);

  useEffect(() => {
    if (strapiPages) {
      let strapiPageButtons = (
        <>
          {strapiPages.menu_items.map((item, index) => {
            if (item.pages.length === 1) {
              // single item
              let isPublic = false; // public, no loggin necessary to view
              let isAuthenticated = false; // loggin necessary, any role can view
              let hasNecessaryRole = false; // role specific routes based on show attendee role
              item.pages[0].visitor_roles.forEach((role) => {
                if (role.Role.toLowerCase() === 'public') {
                  isPublic = true;
                }
                if (
                  role.Role.toLowerCase() === 'authenticated' &&
                  userInformation.user
                ) {
                  isAuthenticated = true;
                }
                if (
                  userInformation?.user?.roles
                    ?.toLowerCase()
                    ?.includes(role.Role.toLowerCase())
                ) {
                  hasNecessaryRole = true;
                }
              });
              if (index === 0) {
                return null;
              }
              if (isPublic || isAuthenticated || hasNecessaryRole) {
                let linkToPage = `/${item.pages[0].slug}`;
                if (item.pages[0].slug.includes('redirect')) {
                  // link to outside website
                  linkToPage = item.pages[0].content;
                  return (
                    <Button
                      className={classes.centerButtons}
                      color="inherit"
                      href={linkToPage}
                    >
                      {item.pages[0].label
                        ? item.pages[0].label
                        : item.pages[0].title}
                    </Button>
                  );
                }
                // link to another internal strapi page
                return (
                  <Button
                    className={classes.centerButtons}
                    color="inherit"
                    component={Link}
                    to={linkToPage}
                  >
                    {item.pages[0].label
                      ? item.pages[0].label
                      : item.pages[0].title}
                  </Button>
                );
              } else {
                return null;
              }
            } else {
              // multiple items
              const dropdownMenuItems = [];
              item.pages.forEach((page) => {
                let isPublic = false; // public, no loggin necessary to view
                let isAuthenticated = false; // loggin necessary, any role can view
                let hasNecessaryRole = false; // role specific routes based on show attendee role
                page.visitor_roles.forEach((role) => {
                  if (role.Role.toLowerCase() === 'public') {
                    isPublic = true;
                  }
                  if (
                    role.Role.toLowerCase() === 'authenticated' &&
                    userInformation.user
                  ) {
                    isAuthenticated = true;
                  }
                  if (
                    userInformation?.user?.roles
                      ?.toLowerCase()
                      ?.includes(role.Role.toLowerCase())
                  ) {
                    hasNecessaryRole = true;
                  }
                });
                if (isPublic || isAuthenticated || hasNecessaryRole) {
                  let linkToPage = `/${page.slug}`;
                  if (page.slug.includes('redirect')) {
                    // link to outside website
                    linkToPage = page.content;
                    dropdownMenuItems.push(
                      <Button href={linkToPage}>
                        <Typography
                          style={{
                            textTransform: 'initial',
                            paddingLeft: '8px',
                          }}
                        >
                          {page.label ? page.label : page.title}
                        </Typography>
                      </Button>
                    );
                  } else {
                    dropdownMenuItems.push(
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to={linkToPage}
                      >
                        {page.label ? page.label : page.title}
                      </MenuItem>
                    );
                  }
                }
              });
              if (dropdownMenuItems.length) {
                return (
                  <div onClick={() => setTabIndex(index)}>
                    <Button
                      id="basic-button"
                      color="inherit"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      {item.label}
                      <ChevronRightIcon
                        style={{ transform: 'rotateZ(90deg)' }}
                      />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open && tabIndex === index}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {dropdownMenuItems}
                    </Menu>
                  </div>
                );
              } else {
                return null;
              }
            }
          })}
        </>
      );
      setPageButtons(strapiPageButtons);
    }
  }, [
    anchorEl,
    classes.centerButtons,
    open,
    strapiPages,
    tabIndex,
    userInformation.user,
  ]);

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar variant="regular" className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
          component={Link}
          to={'/'}
        >
          Home
        </Typography>
        {pageButtons}
        {abstractAvailable &&
        userInformation.user &&
        userInformation.user !== 'unregistered' ? (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/abstractsubmission'}
          >
            Abstract
            <br />
            Submission
          </Button>
        ) : null}
        {!demoEnabled && !landingpageOnly ? (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/registration'}
          >
            {eventInformation.event.under_construction ? 'Register' : 'Enter'}
          </Button>
        ) : null}
        {demoEnabled ? (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/registration'}
          >
            Demo <br />
            Environment
          </Button>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
