import React from 'react';
import { makeStyles } from '@material-ui/core/Styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';

import Hero from '../../Assets/LandingPageImages/Hero3.png';
import Lobby from '../../Assets/LandingPageImages/Lobby1.png';

const useStyles = makeStyles((theme) => ({}));

const Overview = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        layout={'row'}
        spacing={0}
        justify="center"
        align="center"
        style={{ marginTop: '60px' }}
      >
        <Grid item xs={12}>
          <img
            src={Hero}
            alt="Lobby"
            style={{
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              position: 'relative',
              padding: 0,
              margin: 0,
              // top: '-4px',
            }}
          />
          <br />
          <br />
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Grid
          container
          layout={'row'}
          spacing={0}
          justify="center"
          align="center"
          style={{ marginTop: '5vw', marginBottom: '5vw' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            style={{
              paddingLeft: '2vw',
              paddingRight: '2vw',
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              style={{ color: '#122857' }}
            >
              <b>The Virtual Platform Built for Life Science Events</b>
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              align="justify"
              style={{ color: '#122857' }}
            >
              <b>
                Producing and managing events in the pharma world for over
                <i style={{ color: '#e28b3f' }}> 25 years</i>, we understand the
                environment and what it takes to satisfy the meeting
                requirements for R&D, Manufacturing, Clinical, IT, Sales, and
                many other business organizations.
              </b>
            </Typography>
            {/* <br /> */}
            <br />
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={'/registration'}
              size="large"
            >
              View Demo
            </Button>
            <Hidden smUp>
              <br />
              <br />
            </Hidden>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ paddingLeft: '2vw', paddingRight: '2vw' }}
          >
            <img
              src={Lobby}
              alt="Lobby"
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        layout={'row'}
        spacing={0}
        justify="center"
        align="center"
        // style={{
        //   marginTop: '10vw',
        //   marginBottom: '5vw',
        //   backgroundColor: '#122857',
        // }}
      >
        <Grid
          item
          xs={12}
          // style={{ padding: '7vw 0vw' }}
        >
          <Container maxWidth="xl">
            <Typography
              variant="h4"
              align="center"
              style={{ color: '#122857' }}
            >
              <b>"...A Breeze to Navigate!"</b>
            </Typography>
            <br />
            <br />
            <Typography
              variant="h6"
              align="center"
              style={{ color: '#122857' }}
            >
              "PlanetConnect provided an engaging virtual conference environment
              that was a breeze to navigate! Attendees could explore the
              conference program, create personalized agendas, and conveniently
              access content replays. The virtual poster session platform
              supported organic discussion among smaller groups or 1-1 with the
              presenter. The PlanetConnect team did an excellent job ensuring
              planning and logistics for the conference went smoothly.”
            </Typography>
            <br />
            <br />
            <Typography
              variant="h5"
              align="center"
              style={{ color: '#122857' }}
            >
              <b>- Niyanta K.</b> <br />
              Senior Scientist
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
